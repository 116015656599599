import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/Layout'
import Posts from '../components/Posts'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.posts.edges')
    return (
      <Layout>
        <Posts list={posts} />
      </Layout>
    )
  }
}

export default BlogIndex

{
  /* Currently using aliases to query specific folders in our data */
}
{
  /* Specific sections of data can be accessed via props.data.__category__.fields */
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    posts : allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {fileAbsolutePath: {regex: "/posts/.*\\.md$/"}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            source
            link
          }
        }
      }
    }
  }
`
