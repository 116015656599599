import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import get from 'lodash/get'
import { rhythm } from '../utils/typography'

import SectionTitle from '../components/SectionTitle'

const Container = styled.div`
  padding: 4rem 1.2rem;
  margin: auto;
  width: 800px;
  min-height: 400px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    padding: 3rem 1.2rem;
    width: 96%;
  }
`

const Post = styled(Link)`
  border-bottom: 1px solid #000;
  box-shadow: none;
  opacity: 0.8;
  margin-top: 36px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  p {
    font-size: 14px;
  }

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    border: none;
    min-width: 90%;
    margin: 22px 0;
    padding: 0.3rem;
    flex-direction: column;
  }
`

const ExternalPost = styled.a`
  border-bottom: 1px solid #000;
  box-shadow: none;
  opacity: 0.8;
  margin-top: 36px;
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  p {
    font-size: 14px;
  }

  :hover {
    opacity: 1;
  }

  @media screen and (max-width: 900px) {
    border: none;
    min-width: 90%;
    margin: 22px 0;
    padding: 0.3rem;
    flex-direction: column;
  }
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: ${rhythm(1 / 4)};
  text-align: left;

  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
`

class Posts extends React.Component {
  render() {
    return (
      <div id="posts">
        <SectionTitle text="Blog posts" />
        <Container>
          {this.props.list.map(({ node }) => {
            const title = get(node, 'frontmatter.title') || node.fields.slug
            const source = get(node, 'frontmatter.source')
            if (node.frontmatter.source === "Cooper's Personal Blog") {
              return (
                <Post key={node.fields.slug} to={node.fields.slug}>
                  <Title>
                    <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </Title>
                  <small>{node.frontmatter.date}</small>
                </Post>
              )
            } else {
              return (
                <ExternalPost
                  key={node.fields.slug}
                  href={node.frontmatter.link}
                  target="_blank"
                >
                  <Title>
                    <a
                      style={{ boxShadow: 'none' }}
                      href={node.frontmatter.link}
                      target="_blank"
                    >
                      {title}
                    </a>
                  </Title>
                  <small>{node.frontmatter.date}</small>
                </ExternalPost>
              )
            }
          })}
        </Container>
      </div>
    )
  }
}

export default Posts
