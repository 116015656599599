import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => ({
  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },
  a: {
    color: '#313639',
  },
  h1: {
    color: '#313639',
  },
  h2: {
    color: '#313639',
  },
  h3: {
    color: '#313639',
  },
  h4: {
    color: '#313639',
    textTransform: 'none',
  },
  p: {
    color: '#313639',
  },
  small: {
    color: '#313639',
  },
  path: {
    fill: '#aaa',
    stroke: '#ddd',
    strokeWidth: 0.5,
  },
  canvas: {
    minHeight: '100vh !important',
    width: '100vw !important',
    overflow: 'hidden',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  pre: { 
    whiteSpace: 'pre-wrap',
  wordWrap: 'break-word'
  },
  code: {
    fontFamily: 'Courier, New Courier, monospace',
    fontSize: '12px'
  }
})

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
